import React from 'react'
import Background from '../components/img/1622734634-headerabforoil-gas.png'

const Oil = () => {
	

  return (
    <div> <div class="breadcumb-area d-flex align-items-center" style={{backgroundImage: `url(${Background}) `,  backgroundPosition: 'center',backgroundSize:'cover'
}}>
	<div class="container">
		<div class="row d-flex align-items-center">
			<div class="col-lg-12">
				<div class="breadcumb-content">
					<h1>Oil</h1>
					<ul>
						<li><a href="index-2.html">Home</a></li>
						<li> Oil </li>
					</ul>
				</div>
			</div>
			<div class="britcam-shape">
				<div class="breadcumb-content upp">
					<ul>
						<li><a href="index-2.html">Home</a></li>
						<li> Our Service </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
<div id='crudoil' className='text-center' >
	<h2 className='p-3'>Crude Oil Prices</h2>
<iframe src="https://fred.stlouisfed.org/graph/graph-landing.php?g=13xUR&width=670&height=475" scrolling="no" frameborder="0" style={{overflow:'hidden', width:'670px', height:'525px'}} allowTransparency="true" loading="lazy"></iframe> </div>

<iframe class="highcharts-iframe" style={{border: 0, width: '100%', height: '500px', background: 'none'}} src="//cloud.highcharts.com/embed/egecon" bis_size="{&quot;x&quot;:326,&quot;y&quot;:765,&quot;w&quot;:929,&quot;h&quot;:500,&quot;abs_x&quot;:326,&quot;abs_y&quot;:765}" bis_id="fr_g7m9zn7z0eb3830ud5nqgb" bis_depth="0" bis_chainid="1"></iframe>

</div>

)
}

export default Oil