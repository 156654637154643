import React from 'react'
import {VscSettingsGear} from 'react-icons/vsc'
import { useRef } from 'react'
import emailjs from '@emailjs/browser';
import Skypeimg from "../components/img/Skype-icon-new.png"
import Whatsappimg from '../components/img/pngwing.com.png'
import Phoneimg from '../components/img/phone.png'

import Swal from 'sweetalert2'

const Contact = () => {
	const form=useRef()
	const sendEmail = (e) => {
		e.preventDefault();
	
		emailjs.sendForm('service_12c7cho', 'template_mg9yvnj', form.current, 'GKQ7fqzWkIC-ZZO8m')
		  .then((result) => {
 
			Swal.fire({
				icon: 'success',
				title: 'Form Submited'				
			  })		
  }, (error) => {
			  console.log(error.text);
		  });
		  e.target.reset()
	  };
  return (
    <div>
        <div class="breadcumb-area d-flex align-items-center">
	<div class="container">
		<div class="row d-flex align-items-center">
			<div class="col-lg-12">
				<div class="breadcumb-content">
					<h1> Contact Us </h1>
					<ul>
						<li><a href="index-2.html">Home</a></li>
						<li> Contact Us </li>
					</ul>
				</div>
			</div>
			<div class="britcam-shape">
				<div class="breadcumb-content upp">
					<ul>
						<li><a href="index-2.html">Home</a></li>
						<li> Contact Us </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>


<div class="contact-us pt-90 pb-90">
		<div class="container">
			<div class="row">
				<div class="col-sm-12 col-md-6 col-lg-6 pl-0 pr-0">
					<div class="contact_from_box h-100">
						<div class="contact_title pb-4">
							<h3>Get In Touch</h3>
						</div>
						<form id="contact_form" ref={form} onSubmit={sendEmail} >
							<div class="row">
								<div class="col-lg-6">
									<div class="form_box mb-30">
										<input type="text" required name="name_id"  placeholder="Name"/>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="form_box mb-30">
										<input type="email" required name="email_id" placeholder="Email Address"/>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="form_box mb-30">
										<input type="text" required name="number_id" placeholder="Phone Number"/>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="form_box mb-30">
										<input type="text" name="website" placeholder="Website"/>
									</div>
								</div>
								
								<div class="col-lg-12">
									<div class="form_box mb-30">
										<textarea name="message" required id="message" cols="30" rows="10" placeholder="Your Message"></textarea>
									</div>
									<div class="quote_button">
										<button class="btn" type="submit"> <VscSettingsGear/> Free Consultant </button>
									</div>
								</div>
							</div>
						</form>
						<div id="status" className='text-center mt-5'>
						<a href='https://join.skype.com/invite/yzy9QgXvVQGj' target='_blank' className='m-3'>
                        <img src={Skypeimg} style={{width:"50px"}}/>
                        </a>
						<a href='https://www.whatsapp.com/channel/0029Va8ks4QBlHpgg3MUMT39' target='_blank' className='m-3'>
                        <img src={Whatsappimg} style={{width:"50px"}}/>
                        </a>
						<a href='tel:+13103045462' className='m-3'>
                        <img src={Phoneimg} style={{width:"50px"}}/>
                        </a>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-6 col-lg-6 pl-0 pr-0">
					<div class="cda-content-area">
						<div class="cda-single-content d-flex">
							<div class="cda-icon">
								<i class="flaticon-time"></i>
							</div>
							<div class="cda-content-inner">
								<h4>Company Location</h4>
								<p><br/><b className='text-white'>HEADQUARTERS</b><br/>
Houston, USA
17350 State Hwy 249, Ste 220 #11559 Houston, Texas 77064 <br/> <br/><b>OFFICE</b> <br/>
Karachi,Pakistan
Shahrah e Faisal, Karachi, Pakistan</p>
							</div>
						</div>
						<div class="cda-single-content hr d-flex">
							<div class="cda-icon">
								<i class="flaticon-call"></i>
							</div>
							<div class="cda-content-inner">
								<h4>Contact Number</h4>
								<p>USA: +1 (8323888618)<br/>PKR: 0332-2828554</p>
							</div>
						</div>
						<div class="cda-single-content hr d-flex">
							<div class="cda-icon">
								<i class="flaticon-next-1"></i>
							</div>
							<div class="cda-content-inner">
								<h4>Our Email Address</h4>
								<p>contact@nazreenconsulting.tech <br/>info@nazreenconsulting.tech</p>
							</div>
						</div>
						<div class="cda-single-content hr d-flex">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>



    <div class="map-section">
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-12 p-0">
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6998910.153655443!2d-105.37587067351507!3d31.069780330988625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864070360b823249%3A0x16eb1c8f1808de3c!2sTexas%2C%20USA!5e0!3m2!1sen!2s!4v1685101817190!5m2!1sen!2s" width="1920" height="350" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
				</div>
			</div>
		</div>
	</div>
</div>
  )
}

export default Contact