import React from 'react'
import Img1 from '../components/assets/images/about/about-shpe.png'
import Img2 from '../components/assets/images/about/about-2.png'
import Img3 from '../components/assets/images/about/about-shape4.png'
import Img4 from '../components/img/cblsoltionslogo.png'
import Img5 from '../components/img/SDISOLUTION.png'
import Img6 from '../components/img/istockphoto-1181906881-612x612.png'
import Img7 from '../components/img/thunder-shop-logo-design-electric-shop-or-fast-shop-logo-shopping-bag-combined-with-energy-or-lightning-bolt-icon-vector.png'
import Img8 from '../components/img/ANGSOLUTON-LOGO.png'
import {BsFacebook} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'
import {BsDribbble} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
import {VscSettingsGear} from 'react-icons/vsc'
import {BsArrowRightCircle} from 'react-icons/bs'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {Link} from 'react-router-dom'

import Img22 from '../components/img/nazreen employ img/20221027_222630-removebg-preview.png'
import Img23 from '../components/img/nazreen employ img/banner-01.png'
import Img24 from '../components/img/nazreen employ img/shibli.png'
import Img25 from '../components/img/nazreen employ img/WhatsApp Image 2023-05-30 at 1.11.20 PM.png'
import Img26 from '../components/img/nazreen employ img/WhatsApp Image 2023-05-30 at 2.22.32 PM.png'
import Img27 from '../components/img/nazreen employ img/WhatsApp Image 2023-05-30 at 5.14.12 PM.png'
import Img28 from '../components/img/nazreen employ img/WhatsApp Image 2023-05-30 at 7.53.26 PM.png'

import Img29 from '../components/img/nazreen employ img/WhatsApp_Image_2023-05-26_at_5.04.18_PM-removebg-preview.png'
import Img30 from '../components/img/nazreen employ img/WhatsApp Image 2023-05-321 at 2.22.32 PM.png'
import Img31 from '../components/img/nazreen employ img/WhatsApp Image 2023-05-30 at 7.53.26 554355.png'
import Img32 from '../components/img/nazreen employ img/usama.png'
import Img33 from '../components/img/nazreen employ img/balibahi2.png'


const Team = () => {
    const percentage = 95;

  return (
    <div>
        
    <div class="breadcumb-area d-flex align-items-center">
	<div class="container">
		<div class="row d-flex align-items-center">
			<div class="col-lg-12">
				<div class="breadcumb-content">
					<h1> Our Team </h1>
					<ul>
						<li><Link to="index-2.html">Home</Link></li>
						<li> Our Team </li>
					</ul>
				</div>
			</div>
			<div class="britcam-shape">
				<div class="breadcumb-content upp">
					<ul>
						<li><Link to="index-2.html">Home</Link></li>
						<li> Our Team </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>



<div class="about-area style-three upper1">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 col-md-6 ">
				<div class="itsoft-section-title">
					<h5> About Nazreen Consulting </h5>
					<h2> We Are Global Stakeholder Over 2000+  <span> Companies </span></h2>
					<p class="about-text1"> Meet the skilled IT experts at Nazreen Consulting. Our diverse and dedicated team brings extensive experience in software development, network administration, and technology innovation. We're committed to providing top-notch IT solutions with a client-centric approach, emphasizing collaboration, creativity, and customer focus. Discover our team's skills and talents as we help your business thrive in the digital age.</p>
				</div>
				<div class="dreamit-icon-box">
					<div class="dreamit-icon-list">
						<ul>
							<li> <span class='bi bi-arrow-right-circle'><BsArrowRightCircle/></span> <span> Technologies Expertise And Track Record Of Success</span></li>
							<li><BsArrowRightCircle/> <span> Clients Testimonials And Client-Centric Approach</span></li>
						</ul>
					</div>
				</div>

				<div class="progress-box">
                    
					<div class="circle_percent" data-percent="95" style={{background:'white'}}>
                    <CircularProgressbar
  value={percentage}
  text={`${percentage}%`}
  styles={buildStyles({
    // Rotation of path and trail, in number of turns (0-1)
    rotation: 0.25,

    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'

    textSize:`28px`,
   

    // How long animation takes to go from one percentage to another, in seconds
    pathTransitionDuration: 0.5,

    // Can specify path transition in more detail, or remove it entirely
    // pathTransition: 'none',

    // Colors
    pathColor: `red`,
    textColor: 'black',
    trailColor: '#d6d6d6',
    backgroundColor: '#3e98c7',
  })}
/>;
				    </div>
				    <div class="circle-progress-title">
			        	<h4> Clients <br/> Satisfactions </h4>
			        </div>
				    <div class="extra-progress">
				    <div class="circle_percent" data-percent="95" style={{background:'white'}}>
                    <CircularProgressbar
  value={percentage}
  text={`${percentage}%`}
  styles={buildStyles({
    // Rotation of path and trail, in number of turns (0-1)
    rotation: 0.25,

    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'

    textSize:`28px`,
   

    // How long animation takes to go from one percentage to another, in seconds
    pathTransitionDuration: 0.5,

    // Can specify path transition in more detail, or remove it entirely
    // pathTransition: 'none',

    // Colors
    pathColor: `red`,
    textColor: 'black',
    trailColor: '#d6d6d6',
    backgroundColor: '#3e98c7',
  })}
/>;
				    </div>
				    <div class="circle-progress-title">
			        	<h4> Clients <br/> Satisfactions </h4>
			        </div>
				    </div>
				</div>
				<div class="about-button">
					<Link to="/Aboutus"> <VscSettingsGear/> More About </Link>
				</div>
			</div>
			<div class="col-lg-6 col-md-6">
				<div class="dreamit-about-thumb">
					<img src={Img2} alt=""/>
					<div class="about-shape-thumb1 bounce-animate2">
						<img src={Img1} alt=""/>
					</div>
					<div class="about-shape-thumb3 bounce-animate4">
						<img src={Img3} alt=""/>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>



<div class="brand-section upper1">
	<div class="container">
		<div class="row">
			<div class="brand-list d-flex justify-content-around w-100">
				<div class="single-brand ">
					<img src={Img4} alt="Brand"  style={{width:"200px"}}/>
				</div>
				<div class="single-brand">
					<img src={Img5} alt="Brand"  style={{width:"200px"}}/>
				</div>
				<div class="single-brand">
					<img src={Img6} alt="Brand" style={{width:"200px"}}/>
				</div>
				<div class="single-brand">
					<img src={Img7} alt="Brand" style={{width:"200px"}}/>
				</div>
				
			</div>
		</div>
	</div>
</div>



<div class="team_area style-two upp">
<div class="container-fluid">
					<div class="row">
						<div class="col-lg-12">
							<div class="itsoft-section-title upper text-center pb-60">
								<h5> Team Member </h5>
								<h2> Let’s Meet with Our <span> Experts </span></h2>
							</div>
						</div>
					</div>
					<div className='row'>
					<div class="col-lg-3 m-auto col-md-6 offset-md-4">
							<div class="single_team">
								<div class="single_team_thumb1">
									<img src={Img33} alt="" style={{width:"95%",marginBottom:"100px",padding:"0px 0px 0px 20px"}}/>
								</div>
								<div class="single_team_content">
									<div class="team-title">
										<h4>Hasnain Brohi</h4>
										<span>Founder Of Nazreen Consulting</span>
									</div>
									<div class="single_team_icon">
										<Link to="#"> <BsFacebook /> </Link>
										<Link to="#"> <BsTwitter /> </Link>
										<Link to="#"><BsDribbble /></Link>
										<Link to="#"> <BsInstagram /> </Link>
									</div>
								</div>
							</div>
						</div>

					</div>
					<div class="row">
						<div class="col-lg-3 col-md-6">
							<div class="single_team">
								<div class="single_team_thumb1">
									<img src={Img22} alt="" />
								</div>
								<div class="single_team_content">
									<div class="team-title">
										<h4>Sheeraz Samejo</h4>
										<span>Chief Executive Officer</span>
									</div>
									<div class="single_team_icon">
										<Link to="#"> <BsFacebook /> </Link>
										<Link to="#"> <BsTwitter /> </Link>
										<Link to="#"><BsDribbble /></Link>
										<Link to="#"> <BsInstagram /> </Link>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6">
							<div class="single_team">
								<div class="single_team_thumb1">
									<img src={Img23} alt="" />
								</div>
								<div class="single_team_content">
									<div class="team-title">
										<h4> Absar Rizvi </h4>
										<span> IT-Head </span>
									</div>
									<div class="single_team_icon">
										<Link to="#"> <BsFacebook /> </Link>
										<Link to="#"> <BsTwitter /> </Link>
										<Link to="#"><BsDribbble /></Link>
										<Link to="#"> <BsInstagram /> </Link>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6">
							<div class="single_team">
								<div class="single_team_thumb1">
									<img src={Img26} alt="" />
								</div>
								<div class="single_team_content">
									<div class="team-title">
										<h4>Farhan Samejo</h4>
										<span>eCommerce Head</span>
									</div>
									<div class="single_team_icon">
										<Link to="#"> <BsFacebook /> </Link>
										<Link to="#"> <BsTwitter /> </Link>
										<Link to="#"><BsDribbble /></Link>
										<Link to="#"> <BsInstagram /> </Link>
									</div>
								</div>
							</div>
						</div>	

						<div class="col-lg-3 col-md-6">
							<div class="single_team">
								<div class="single_team_thumb1">
									<img src={Img30} alt="" />
								</div>
								<div class="single_team_content">
									<div class="team-title">
										<h4>Faraz Mallah</h4>
										<span>Customer Representative Officer</span>
									</div>
									<div class="single_team_icon">
										<Link to="#"> <BsFacebook /> </Link>
										<Link to="#"> <BsTwitter /> </Link>
										<Link to="#"><BsDribbble /></Link>
										<Link to="#"> <BsInstagram /> </Link>
									</div>
								</div>
							</div>
						</div>
						
						<div class="col-lg-3 col-md-6">
							<div class="single_team">
								<div class="single_team_thumb1">
									<img src={Img31} alt="" />
								</div>
								<div class="single_team_content">
									<div class="team-title">
										<h4>Lena Lucas</h4>
										<span>Customer Representative Officer</span>
									</div>
									<div class="single_team_icon">
										<Link to="#"> <BsFacebook /> </Link>
										<Link to="#"> <BsTwitter /> </Link>
										<Link to="#"><BsDribbble /></Link>
										<Link to="#"> <BsInstagram /> </Link>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6">
							<div class="single_team">
								<div class="single_team_thumb1">
									<img src={Img27} alt="" />
								</div>
								<div class="single_team_content">
									<div class="team-title">
										<h4>Khanji </h4>
										<span>Customer Representative Officer</span>
									</div>
									<div class="single_team_icon">
										<Link to="#"> <BsFacebook /> </Link>
										<Link to="#"> <BsTwitter /> </Link>
										<Link to="#"><BsDribbble /></Link>
										<Link to="#"> <BsInstagram /> </Link>
									</div>
								</div>
							</div>
						</div>
						
						
						
						
						<div class="col-lg-3 col-md-6">
							<div class="single_team">
								<div class="single_team_thumb1">
									<img src={Img24} alt="" />
								</div>
								<div class="single_team_content">
									<div class="team-title">
										<h4>Saffiullah</h4>
										<span>Customer Representative Officer</span>
									</div>
									<div class="single_team_icon">
										<Link to="#"> <BsFacebook /> </Link>
										<Link to="#"> <BsTwitter /> </Link>
										<Link to="#"><BsDribbble /></Link>
										<Link to="#"> <BsInstagram /> </Link>
									</div>
								</div>
							</div>
						</div>
						
				
			
					</div>
				</div>
</div>
</div>
  )
}

export default Team