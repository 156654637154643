import React from 'react'
import Img1 from '../components/img/1-3969877565-640x360.jpg'
import Img2 from '../components/img/1-4080668692-640x360.jpg'
import Img3 from '../components/img/1-4038739537-640x360.jpg'
import Img4 from '../components/img/1-4083282730-640x427.jpg'
import Img5 from '../components/img/1-4079031283-640x479.jpg'
import Img6 from '../components/img/1-4079032113-640x480.jpg'
import Background from '../components/img/tdy_home_prices_220403-no4u4u.jpg'
import {Link} from 'react-router-dom'
const Buy = () => {
  return (
    <div class='my-5'>

		<div class="breadcumb-area d-flex align-items-center" style={{backgroundImage: `url(${Background}) `,  backgroundPosition: 'center',backgroundSize:'cover'
}}>

	<div class="container">
		<div class="row d-flex align-items-center">
			<div class="col-lg-12">
				<div class="breadcumb-content">
					<h1>Buy
</h1>
					<ul>
						<li><a href="index-2.html">Home</a></li>
						<li>Buy
 </li>
					</ul>
				</div>
			</div>
			<div class="britcam-shape">
				<div class="breadcumb-content upp">
					<ul>
						<li><a href="index-2.html">Home</a></li>
						<li> Buy </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>

<div className='container'>
	<div className='row'>
		<div className='col-lg-4'>
<h2>Plot #1</h2>
<img src={Img1} alt="" width='100%'/>
<p><h4>$1,250,000 – 14.76 acres</h4>
14.76 acres located 15 miles South of College Station on the North side of Navasota. Approximately 450 feet from the FM 3090 overpass at Highway 6. The front 250′ is zoned B-1 for General Business and the back zoned A/O for Open Space allowing for numerous business oppurtunites.</p>	
<Link to='/Contact' className='btn btn-block btn-outline-primary '>Contact Us</Link>
</div>
		<div className='col-lg-4'>
		<h2>Plot #2</h2>
<img src={Img2} alt="" width='100%'/>
<p><h4>$759,645 – 33.76 acres</h4>
32 acres of unrestricted Wise County with 1750 plus feet of Hwy 199 frontage. This blank canvas is perfect for development! Property is also ideal for building the horse property of your dreams and raising fabulous costal hay. Ag exempt with Hay Productions makes it the perfect buy and hold for someone looking to invest.</p>	
<Link to='/Contact' className='btn btn-block btn-outline-primary '>Contact Us</Link>
		</div>
		<div className='col-lg-4'>
		<h2>Plot #3</h2>
<img src={Img3} alt="" width='100%'/>
<p><h4>$3,433,500 – 109 acres</h4>
Tract 1 : 49 acres +/- $34,500 per acre. Total price: $1,690,500 Water: Well, septic Comments: Incredible views, Highway 71 frontageTract 2: 60 acres +/- $34,500 per acre. Total price: $2,070,000 Water: Large pond, one well not completed. Molasses Creek running through property. Comments: Improved pasture, Highway 71 frontage</p>	
<Link to='/Contact' className='btn btn-block btn-outline-primary '>Contact Us</Link>
		</div>
		<div className='col-md-12 p-4'></div>
		<div className='col-lg-4'>
		<h2>House #1</h2>
<img src={Img4} alt="" width='100%'/>
<p><h4>$349,400 – 28 acres</h4>
9659 azeem avanue 77099 Hoston</p>	
<Link to='/Contact' className='btn btn-block btn-outline-primary '>Contact Us</Link>		
</div>
		<div className='col-lg-4'>
		<h2>House #2</h2>
<img src={Img5} alt="" width='100%'/>
<p><h4>$598,000 – 80 acres</h4>
9661 azeem avanue 77099 Hoston</p>	
<Link to='/Contact' className='btn btn-block btn-outline-primary '>Contact Us</Link>	
		</div>
		<div className='col-lg-4'>
		<h2>House #3</h2>
<img src={Img6} alt="" width='100%'/>
<p><h4>$799,000 – 55 acres</h4>
9655 azeem avanue 77099 Hoston</p>	
<Link to='/Contact' className='btn btn-block btn-outline-primary '>Contact Us</Link>	
		</div>
	</div>
</div>
</div>
  )
}

export default Buy