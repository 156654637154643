import React from 'react'
import Background from '../components/img/uk-business-leaders-say-success-technology-background-linked-1013x440.jpeg.webp';
import './Investment.css'
const Investment = () => {
  return (
    <div><div class="breadcumb-area d-flex align-items-center" style={{backgroundImage: `url(${Background}) `,  backgroundPosition: 'center',backgroundSize:'cover'
}}>
	<div class="container">
		<div class="row d-flex align-items-center">
			<div class="col-lg-12">
				<div class="breadcumb-content">
					<h1>Invest
</h1>
<h2 className='text-white my-3 w-70'>Nazreen consulting is giving you a opportunity to invest <br/> Earn profit every month</h2>
					<ul>
						<li><a href="index-2.html">Home</a></li>
						<li>Invest
 </li>
					</ul>
				</div>
			</div>
			<div class="britcam-shape">
				<div class="breadcumb-content upp">
					<ul>
						<li><a href="index-2.html">Home</a></li>
						<li>Invest </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>


<div class="row bg">
    <div class="col-md-4 col-sm-12">
        <div class="pricing-table-3 basic">
            <div class="pricing-table-header">
                <h4 class="text-white"><strong>Investment</strong><br/>Bronze</h4>
                

            </div>
            <div class="price"><strong>$1K</strong> / 3 Year</div>
            <div class="pricing-body">
                    <h6 class='text-center text-secondary '> Profit</h6 >
                    <hr/>
                
                <h4 class='text-center'>$50/Per Month</h4>

                <hr/>
                <h6 class='text-center pt-3 text-secondary'> 3 Year Total
                </h6>
                <hr/>
                
                <h4 class='text-center'>$1800/Total</h4>
                <hr/>
                <a href="/Contact" class="view-more">View More</a></div>
        </div>
    </div>
    <div class="col-md-4 col-sm-12">
        <div class="pricing-table-3 basic">
            <div class="pricing-table-header">
                <h4 class="text-white"><strong>Investment</strong><br/>Sliver</h4>
                

            </div>
            <div class="price"><strong>$5K</strong> / 3 Year</div>
            <div class="pricing-body">
                    <h6 class='text-center text-secondary '> Profit</h6 >
                    <hr/>
                
                <h4 class='text-center'>$240/Per Month</h4>

                <hr/>
                <h6 class='text-center pt-3 text-secondary'> 3 Year Total
                </h6>
                <hr/>
                
                <h4 class='text-center'>$8640/Total</h4>
                <hr/>
                <a href="/Contact" class="view-more">View More</a></div>
        </div>
    </div>
    <div class="col-md-4 col-sm-12">
        <div class="pricing-table-3 basic">
            <div class="pricing-table-header">
                <h4 class="text-white"><strong>Investment</strong><br/>Gold</h4>
                

            </div>
            <div class="price"><strong>$15K</strong> / 3 Year</div>
            <div class="pricing-body">
                    <h6 class='text-center text-secondary '> Profit</h6 >
                    <hr/>
                
                <h4 class='text-center'>$700/Per Month</h4>

                <hr/>
                <h6 class='text-center pt-3 text-secondary'> 3 Year Total
                </h6>
                <hr/>
                
                <h4 class='text-center'>$25200/Total</h4>
                <hr/>
                <a href="/Contact" class="view-more">View More</a></div>
        </div>
    </div>
    <div class="col-md-4 col-sm-12">
        <div class="pricing-table-3 basic">
            <div class="pricing-table-header">
                <h4 class="text-white"><strong>Investment</strong><br/>Platinum</h4>
                

            </div>
            <div class="price"><strong>$30K</strong> / 3 Year</div>
            <div class="pricing-body">
                    <h6 class='text-center text-secondary '> Profit</h6 >
                    <hr/>
                
                <h4 class='text-center'>$1400/Per Month</h4>

                <hr/>
                <h6 class='text-center pt-3 text-secondary'> 3 Year Total
                </h6>
                <hr/>
                
                <h4 class='text-center'>$50400/Total</h4>
                <hr/>
                <a href="/Contact" class="view-more">View More</a></div>
        </div>
    </div>
    <div class="col-md-4 col-sm-12">
        <div class="pricing-table-3 basic">
            <div class="pricing-table-header">
                <h4 class="text-white"><strong>Investment</strong><br/>Titanium </h4>
                

            </div>
            <div class="price"><strong>$50K</strong> / 3 Year</div>
            <div class="pricing-body">
                    <h6 class='text-center text-secondary '> Profit</h6 >
                    <hr/>
                
                <h4 class='text-center'>$2200/Per Month</h4>

                <hr/>
                <h6 class='text-center pt-3 text-secondary'> 3 Year Total
                </h6>
                <hr/>
                
                <h4 class='text-center'>$79200/Total</h4>
                <hr/>
                <a href="/Contact" class="view-more">View More</a></div>
        </div>
    </div>
    <div class="col-md-4 col-sm-12">
        <div class="pricing-table-3 basic">
            <div class="pricing-table-header">
                <h4 class="text-white"><strong>Investment</strong><br/>Mega</h4>
                

            </div>
            <div class="price"><strong>$100k</strong> / 3 Year</div>
            <div class="pricing-body">
                    <h6 class='text-center text-secondary '> Profit</h6 >
                    <hr/>
                
                <h4 class='text-center'>$4500/Per Month</h4>

                <hr/>
                <h6 class='text-center pt-3 text-secondary'> 3 Year Total
                </h6>
                <hr/>
                
                <h4 class='text-center'>$162K/Total</h4>
                <hr/>
                <a href="/Contact" class="view-more">View More</a></div>
        </div>
    </div>

</div>



</div>
  )
}

export default Investment