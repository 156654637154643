import React from 'react'
import Background from '../components/img/uk-business-leaders-say-success-technology-background-linked-1013x440.jpeg.webp';

const Invest = () => {
	
  return (
    <div><div class="breadcumb-area d-flex align-items-center" style={{backgroundImage: `url(${Background}) `,  backgroundPosition: 'center',backgroundSize:'cover'
}}>
	<div class="container">
		<div class="row d-flex align-items-center">
			<div class="col-lg-12">
				<div class="breadcumb-content">
					<h1>Invest
</h1>
<h2 className='text-white my-3 w-70'>Nazreen consulting is giving you a opportunity to invest in different technologies to achieve your goals </h2>
					<ul>
						<li><a href="index-2.html">Home</a></li>
						<li>Invest
 </li>
					</ul>
				</div>
			</div>
			<div class="britcam-shape">
				<div class="breadcumb-content upp">
					<ul>
						<li><a href="index-2.html">Home</a></li>
						<li>Invest </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
<div className='my-5 text-center'><h1>Coming Soon</h1></div>
</div>
  )
}

export default Invest