import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import logo from '../components/img/1280X1024.png'
import './Dropdown.css'
import { Link } from 'react-router-dom'
import './Navbar.css'
import Skypeimg from "../components/img/Skype-icon-new.png"
import Whatsappimg from '../components/img/pngwing.com.png'
import Phoneimg from '../components/img/phone.png'

const Navbar = () => {

    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const closeMenu = () => setClick(false)

    const [color, setColor] = useState(false)
    const changeColor = () => {
        if (window.scrollY >= 150) {
            setColor(true)
        } else {
            setColor(false)

        }
    }

    window.addEventListener('scroll', changeColor)

    return (
        <div className={ color ? 'header header-bg' : 'header'}>
            <nav className='navbar'>
                <Link to='/' className='logo'>
                    <img src={logo} alt='logo' />
                </Link>
                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
                        : (<FaBars size={30} style={{ color: '#ffffff' }} />)}

                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <li className='nav-item'>
                        <Link to='/' onClick={closeMenu}>Home</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Aboutus' onClick={closeMenu}>About</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Service' onClick={closeMenu}>Service</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Portfolio' onClick={closeMenu}>Portfolio</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Team' onClick={closeMenu}>Team</Link>
                    </li>
                   
                    <li className='nav-item'>
                        <Link to='/Contact' onClick={closeMenu}>Contact</Link>
                    </li>
                    <li className='nav-item'>
                    <div class="dropdown">
  <button class="dropbtn">Oil & Gas</button>
  <div class="dropdown-content">
    <Link  to="/Oil" onClick={closeMenu}>Oil</Link>
    <Link to="/Gas" onClick={closeMenu}>Gas</Link>
    <Link to="/Oli_gas_whole_sale_rate" onClick={closeMenu}>Oil & Gas Whole Sale Rate</Link>
  </div>
</div>
                    </li>
                    <li className='nav-item'>
                    <div class="dropdown">
  <button class="dropbtn">RealEstate/Invest</button>
  <div class="dropdown-content">
    <Link to="/Buy" onClick={closeMenu}>Buy</Link>
    <Link to="/Invest" onClick={closeMenu}>Invest</Link>
  </div>
</div>                    </li>
<li className='nav-item'>
                        <Link to='/Investment' onClick={closeMenu}>Investment</Link>
                    </li>
                    <li className='nav-item'>
                        <a href='https://mantos.app/'>Mantos</a>
                    </li>
                    <li className='nav-item'>
                        <a href='https://join.skype.com/invite/yzy9QgXvVQGj' target='_blank'>
                        <img src={Skypeimg} style={{width:"30px"}}/>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='https://www.whatsapp.com/channel/0029Va8ks4QBlHpgg3MUMT39' target='_blank'>
                        <img src={Whatsappimg} style={{width:"30px"}}/>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='tel:+13103045462'>
                        <img src={Phoneimg} style={{width:"30px"}}/>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Navbar
